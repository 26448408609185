import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton, EuiSpacer } from '@elastic/eui';
import SettingsTable from 'components/Internet_Access/Port_Forwarding/Pirelli/SettingsTable';
import NavButtons from 'components/Internet_Access/Port_Forwarding/NavButtons';
import ForumBox from 'components/Internet_Access/Port_Forwarding/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Port Forwarding - Pirelli Router",
  "path": "/Internet_Access/Port_Forwarding/Pirelli/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Connect to your Camera through the Internet",
  "image": "./IA_SearchThumb_Portforwarding_Pirelli.png",
  "social": "/images/Search/IA_SearchThumb_Portforwarding_Pirelli.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-Port-Forwarding_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Port Forwarding - Pirelli Router' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Connect to your Camera through the Internet' image='/images/Search/IA_SearchThumb_Portforwarding_Pirelli.png' twitter='/images/Search/IA_SearchThumb_Portforwarding_Pirelli.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Port_Weiterleitung/Pirelli/' locationFR='/fr/Internet_Access/Port_Forwarding/Pirelli/' crumbLabel="Pirelli" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
# Port Forwarding
    <h2 {...{
      "id": "pirelli-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pirelli-router",
        "aria-label": "pirelli router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pirelli Router`}</h2>
    <p>{`This is the 2nd step of the Port Forwarding, click `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`here`}</a>{` to get to Step I.`}</p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Access the user interface of your router in your browser (click `}<a parentName="p" {...{
        "href": "http://portforward.com/networking/routers_ip_address.htm"
      }}>{`here`}</a>{` for more information) and type in your login credentials (click `}<a parentName="p" {...{
        "href": "http://portforward.com/default_username_password/Pirelli.htm"
      }}>{`here`}</a>{` for more information). Navigate to the `}<strong parentName="p">{`Portforwarding`}</strong>{` in the `}<strong parentName="p">{`Sicherheit`}</strong>{` menu. Click on `}<strong parentName="p">{`Neuer Eintrag`}</strong>{` to add a port forwarding rule for your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6b3a8989e6489f2f17d148351654b666/0a47e/Pirelli-A1-Port-forwarding_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQklEQVQoz42S207bQBRF/ZdVfwa+AKkIUS7h+lBBidQCL9AKAjixHQwkzsUex44TbJLQqtieYDue8Uw8VeyqApWHLs3j2Zqz9z4cxpgQkmTEcYwQiiaYpewlJJsg0ylBiLyA04FmWZZj20DTLEOv66Mv5537+77jOJZlGYbR7/UoIYyxRO9gXaeMUUJoBifI1ydn59W7Wr2tNtT2kx8FUZKmdDqdZv/hCUZpmrJKmTUU9hpOOz5Sjo9rJydSsdg8PdUBuOLL1eq1IEoVQRREqS1da18PlXfvG2elG0G8uOQrgsiXK1d8mavt71/t7dlzc3JhQy2VTr99X1xeXytsrm7srBR21gpbBx+WigtLn1e3DhaWP31cWdnaLWxur21sr2/ucoraqpmGNT9/c3R0p+u5vX9IWTpNsjg9z/U81/e9MYTcrSw3Nc2SZVA8UIBGkgShGKOY0rwEHE9iPIuaBmEYRWHw/BxFYRRFYRhytzdy6fJS5svy4aHSVgnGQRQ5gx+NpqrqJjB6v/zQ7NnNNtBA9955/PkEQacLOlbffuSGo8FwOAzCMETIdV1CiOdDpaFeXPB8WWqroGv1RKkqiFKrpRpmr9XW6vUG0Dt6p8uNHkfD4YBSyhhD2Q24nm9Yttl3+s4A+tD3ZvbGMyCE/hjCIHgej+HMc5qmlNJZk4zFCCUJRghNMsLMW24vB2a4rgsh9DyPe5lpkiT5CrN80/z9Id/Ltm3HcYAGHh4euqb5Sowx/iv+H16J4zgmb/f8tvg3FFby7GnJlioAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b3a8989e6489f2f17d148351654b666/e4706/Pirelli-A1-Port-forwarding_01.avif 230w", "/en/static/6b3a8989e6489f2f17d148351654b666/d1af7/Pirelli-A1-Port-forwarding_01.avif 460w", "/en/static/6b3a8989e6489f2f17d148351654b666/56a33/Pirelli-A1-Port-forwarding_01.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6b3a8989e6489f2f17d148351654b666/a0b58/Pirelli-A1-Port-forwarding_01.webp 230w", "/en/static/6b3a8989e6489f2f17d148351654b666/bc10c/Pirelli-A1-Port-forwarding_01.webp 460w", "/en/static/6b3a8989e6489f2f17d148351654b666/e88ff/Pirelli-A1-Port-forwarding_01.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6b3a8989e6489f2f17d148351654b666/81c8e/Pirelli-A1-Port-forwarding_01.png 230w", "/en/static/6b3a8989e6489f2f17d148351654b666/08a84/Pirelli-A1-Port-forwarding_01.png 460w", "/en/static/6b3a8989e6489f2f17d148351654b666/0a47e/Pirelli-A1-Port-forwarding_01.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6b3a8989e6489f2f17d148351654b666/0a47e/Pirelli-A1-Port-forwarding_01.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Type your camera´s IP-address into the field `}<strong parentName="p">{`Lokaler Rechner`}</strong>{`. You can find your cameras IP address using our IP Camera Tool. Click on Ok to save your settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75493f57ac067f53e43e68fdea132348/0a47e/Pirelli-A1-Port-forwarding_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmUlEQVQoz43Sa2/aMBQGYP//H8UnSqW1QmuhY0EoWxJ8N77iWxfiiWQMum5TH8XSieVXtpMDhqFcXKvzyweAEIIxxlqDuVPGv77mGGNKKed8HjmlnFJKP/4GfH562mw2VfX10xe82bUIdgghxhhEsG2abtQ03/u+L++A+Xx+v7h/fl6VUmIM1lrvfRjdFnHknNNaT/MxRjC/m89ms9XqHD4cRNs23b6z1k6ZWyEELjgiWFtjjfHeg5f1y3K5rKpqGAbnLGOMUKqUDiF6H35tfaEgpNut4dwdj8458PDwuFgs1ut1KUUI/q2uMcZKqfBODIG0bbfbHbX247lA13UYIUJIKYUQUte1HAkh+FtCiD2ClHGltTwcpJTAh1BKyTn3fW+t5ZwTSiDcU0ZCDOnqXEspMUYYIwjhfg/BMHZJSmkYBoRQtd0yzgnGlFKllTb68hjnHKGEc66UYowJzsH0x6Y2MMZIKePF7ad2zh2kpJQppXNOMcYQwpvwvxrzfK+UKKVt21LKfq+5hk+n0zRbPuzP8H/cHmQK/wTuER06NN4XKAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75493f57ac067f53e43e68fdea132348/e4706/Pirelli-A1-Port-forwarding_02.avif 230w", "/en/static/75493f57ac067f53e43e68fdea132348/d1af7/Pirelli-A1-Port-forwarding_02.avif 460w", "/en/static/75493f57ac067f53e43e68fdea132348/56a33/Pirelli-A1-Port-forwarding_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75493f57ac067f53e43e68fdea132348/a0b58/Pirelli-A1-Port-forwarding_02.webp 230w", "/en/static/75493f57ac067f53e43e68fdea132348/bc10c/Pirelli-A1-Port-forwarding_02.webp 460w", "/en/static/75493f57ac067f53e43e68fdea132348/e88ff/Pirelli-A1-Port-forwarding_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75493f57ac067f53e43e68fdea132348/81c8e/Pirelli-A1-Port-forwarding_02.png 230w", "/en/static/75493f57ac067f53e43e68fdea132348/08a84/Pirelli-A1-Port-forwarding_02.png 460w", "/en/static/75493f57ac067f53e43e68fdea132348/0a47e/Pirelli-A1-Port-forwarding_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75493f57ac067f53e43e68fdea132348/0a47e/Pirelli-A1-Port-forwarding_02.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`Set the `}<strong parentName="p">{`Protokoll`}</strong>{` to `}<strong parentName="p">{`Benutzerdefiniert`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c880d5c8d028ce4679f7e938e0584f8f/0a47e/Pirelli-A1-Port-forwarding_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQoz43SS28TMRAH8P3+n4EbqsQRAQckTiBUKh5NUygpS1M1reP4NR57Y3s3u7WNdrevpFTwk0+Wxn/bM0WM1yml/IQY4/XTiv3psjynoCTnAhQAgJRS3Go2m/GUNNg5upgTzSUIIQBAo1ZKMcZWqxXnXAgRQhjDY4wppZ2LFDmlEEJVWWttCKHrurZtu0Hbtk3T1AOttZTSOee9r28VOWcNsLhcXCwulFLOufXfCCkopYhojLnb7IurylJKyXLJOa8fCcEH70BJgxhC7Qf3yYhICBFCImr3SB8RIwWQWlvvtEFtEK1Ba/pixlhZlgrAWouIAKAekFqbvb13z18+e3vy4iN59TO8mdWvh1WklNbrtZSCMXY18N6Pv3Wj67qy/PH+w5f9z+dnl8rWvs1u068+mXM+n8+FEJxzxpjW2myzdT35fvz7rMx5q9V9slLq6opsPfKBylqDeDw9Ojj4xBgbR+ameOz+3QztGHpR0SWdTo8mk0MF4L2/T87/Mg7WbHby7fBr0zRb187/51d5SgjZGe8/XKQciLQ9Bz4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c880d5c8d028ce4679f7e938e0584f8f/e4706/Pirelli-A1-Port-forwarding_03.avif 230w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/d1af7/Pirelli-A1-Port-forwarding_03.avif 460w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/56a33/Pirelli-A1-Port-forwarding_03.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c880d5c8d028ce4679f7e938e0584f8f/a0b58/Pirelli-A1-Port-forwarding_03.webp 230w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/bc10c/Pirelli-A1-Port-forwarding_03.webp 460w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/e88ff/Pirelli-A1-Port-forwarding_03.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c880d5c8d028ce4679f7e938e0584f8f/81c8e/Pirelli-A1-Port-forwarding_03.png 230w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/08a84/Pirelli-A1-Port-forwarding_03.png 460w", "/en/static/c880d5c8d028ce4679f7e938e0584f8f/0a47e/Pirelli-A1-Port-forwarding_03.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c880d5c8d028ce4679f7e938e0584f8f/0a47e/Pirelli-A1-Port-forwarding_03.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`Give your camera a name and click on `}<strong parentName="p">{`Neue Server Ports`}</strong>{` to add your camera´s port.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3dd7bb2f559ed03bb9366085101a183f/0a47e/Pirelli-A1-Port-forwarding_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABi0lEQVQoz5WRy47UMBBF899sWLLgO1gxEl+AYIeExIyG7k7S6XTodF62M4nt2I5fsUdOQDAaHuLoLlyW762SK3Ir3vvl/4kwJnCsAand4q01xhj7jN9eWmujIq9v3r969+X1KS3zc3aID0maUEq999tEfyGK42QkLZfDVmttlFJaaynlvCKVUssilZJPCWPv9/vvOWsbSknfI4QQYyykGMOGB3TKGMZinsUPOOfOuShO4tXotjkxxmVZXi6XcRyVUlJrhvp2t5uGgc8zF4ILwRgzxgTz8Xi01hZFnp+OdX2VnAnOJzYRQjAeMR4IJZMQhIY6iJBpopyzaaJRmqbLYsuqO2RVekWf0uLz7a7qHmo4/hQYNjUQZ+fr7f3h7mtcNigqisI517YwqUD25u39i5fnsgIAdr+obcG5+AYgQn0PIeoAbJqu7UAkhNBaKzkro804yI8fRJrMSkkhwlevCgcpKSEIIQhB13WMMSllMP9zn9sDSmndNGV5yfNcCBH2/MTsnLfW/yHreY9HB22qHXQpMAwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dd7bb2f559ed03bb9366085101a183f/e4706/Pirelli-A1-Port-forwarding_04.avif 230w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/d1af7/Pirelli-A1-Port-forwarding_04.avif 460w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/56a33/Pirelli-A1-Port-forwarding_04.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3dd7bb2f559ed03bb9366085101a183f/a0b58/Pirelli-A1-Port-forwarding_04.webp 230w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/bc10c/Pirelli-A1-Port-forwarding_04.webp 460w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/e88ff/Pirelli-A1-Port-forwarding_04.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dd7bb2f559ed03bb9366085101a183f/81c8e/Pirelli-A1-Port-forwarding_04.png 230w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/08a84/Pirelli-A1-Port-forwarding_04.png 460w", "/en/static/3dd7bb2f559ed03bb9366085101a183f/0a47e/Pirelli-A1-Port-forwarding_04.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3dd7bb2f559ed03bb9366085101a183f/0a47e/Pirelli-A1-Port-forwarding_04.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<strong parentName="p">{`TCP`}</strong>{` as your service protocol and set the `}<strong parentName="p">{`Quellport`}</strong>{` to `}<strong parentName="p">{`Beliebig`}</strong>{`. The destination Port `}<strong parentName="p">{`Zielport`}</strong>{` is your camera´s internal port. Here you enter the port `}<em parentName="p">{`8081`}</em>{` for your first camera, for your second camera enter `}<em parentName="p">{`8082`}</em>{` etc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/acfc919b6f34032a9face9622f420c43/0a47e/Pirelli-A1-Port-forwarding_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABEElEQVQoz9XQPW/CMBAGYP//vb+lC2NJER2IygAhJYlrR0nq1AlB/vY5FaRCFYKBsY9Osu61T5YOaa2llEKIg+idd/4+56+vUZqm222y2ayfV0/74oMQQiihJaWUYIyzLBNCjJMQTvUHiqJoHkVFkSvbjQ9Ci8XrbDarq9pZxxiTSsmblBLHo+p7NRzkMEwZiuP4bbls6qb9Zuv1e9u2xhh1RUpjLS8pThLO2LGutRBKKbRaxS/zeVVVUsrdbsc5BwB3xVofguh4k+esLIevxp9jRGmJP3HXdeeNBAC4bPfs9zDWWGtPvfeXN8g5F0IwxoQQpghuYYxhjPf7TGs9/QEAaJoxxoyPQ+M4AsB/G/4B5iW2e/VTuNQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acfc919b6f34032a9face9622f420c43/e4706/Pirelli-A1-Port-forwarding_05.avif 230w", "/en/static/acfc919b6f34032a9face9622f420c43/d1af7/Pirelli-A1-Port-forwarding_05.avif 460w", "/en/static/acfc919b6f34032a9face9622f420c43/56a33/Pirelli-A1-Port-forwarding_05.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/acfc919b6f34032a9face9622f420c43/a0b58/Pirelli-A1-Port-forwarding_05.webp 230w", "/en/static/acfc919b6f34032a9face9622f420c43/bc10c/Pirelli-A1-Port-forwarding_05.webp 460w", "/en/static/acfc919b6f34032a9face9622f420c43/e88ff/Pirelli-A1-Port-forwarding_05.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acfc919b6f34032a9face9622f420c43/81c8e/Pirelli-A1-Port-forwarding_05.png 230w", "/en/static/acfc919b6f34032a9face9622f420c43/08a84/Pirelli-A1-Port-forwarding_05.png 460w", "/en/static/acfc919b6f34032a9face9622f420c43/0a47e/Pirelli-A1-Port-forwarding_05.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/acfc919b6f34032a9face9622f420c43/0a47e/Pirelli-A1-Port-forwarding_05.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h3>
    <p>{`To be able to access the Flash Video as well, there has to be set a second Port Forwarding. Just follow the manual of this step, with the only difference to set the Flash (RTMP) Port instead. So for your first camera all ports are set to 1935, for your second camera it´s 1936, etc.`}</p>
    <p>{`Now all Port Forwardings for your camera are set in the router. You can now continue with the next step:`}</p>
    <h2 {...{
      "id": "step-iii-the-cameras-ddns-address-and-the-remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-iii-the-cameras-ddns-address-and-the-remote-access",
        "aria-label": "step iii the cameras ddns address and the remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step III: The Camera´s DDNS-Address and the Remote-Access`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/Mobile_Access/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Step III</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "updated-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#updated-user-interface",
        "aria-label": "updated user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Updated User Interface`}</h2>
    <p>{`If your Pirelli router is using another WebUI, follow these instructions:`}</p>
    <SettingsTable mdxType="SettingsTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/771597286c896ed0c5d2f0857646e7df/0a47e/Pirelli-A1-Port-forwarding_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAABU0lEQVQoz3XQ227CMAwG4L77gCvuyoYm8WBMKiVpDm5CTk5ho5mQprZQyhDfTVrJlv07Wy6XeZ5vNps8zz97q9X7+mO9WCxmV2/DM5/PZ4+y7XZblqVSSggBPSllXddCCM45Z4wzzl7ItG9+UzqdTt83x+MRpAQJzjlsvIvq54XsfD5778OE955zvit2XHA4MDjQGDFOIOJQmaW2pRT2e1nugXNtjLHWaq1ZxboMmknFrLVuwlprellKyblu9nS8ORwIoXUNtaFgiPMmxuZ5eNf8b21ExIhFUVBaCU0pfAV0zaNrc5uSDx4x3IIEpZSUsqKVBNBWaQsxNmNOfzNMbvtE3rnu3zlHeqzie1FSRTFgCH5sm+6YtbeDEQJaG+eutwGQqq61kdpA8NfO4VqjLnN4XDsgWmMIIRJAGaksH6+F96J+8uVyae/SYPzq3ja1zxW9P7JyhkV7gKdXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/771597286c896ed0c5d2f0857646e7df/e4706/Pirelli-A1-Port-forwarding_06.avif 230w", "/en/static/771597286c896ed0c5d2f0857646e7df/d1af7/Pirelli-A1-Port-forwarding_06.avif 460w", "/en/static/771597286c896ed0c5d2f0857646e7df/56a33/Pirelli-A1-Port-forwarding_06.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/771597286c896ed0c5d2f0857646e7df/a0b58/Pirelli-A1-Port-forwarding_06.webp 230w", "/en/static/771597286c896ed0c5d2f0857646e7df/bc10c/Pirelli-A1-Port-forwarding_06.webp 460w", "/en/static/771597286c896ed0c5d2f0857646e7df/e88ff/Pirelli-A1-Port-forwarding_06.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/771597286c896ed0c5d2f0857646e7df/81c8e/Pirelli-A1-Port-forwarding_06.png 230w", "/en/static/771597286c896ed0c5d2f0857646e7df/08a84/Pirelli-A1-Port-forwarding_06.png 460w", "/en/static/771597286c896ed0c5d2f0857646e7df/0a47e/Pirelli-A1-Port-forwarding_06.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/771597286c896ed0c5d2f0857646e7df/0a47e/Pirelli-A1-Port-forwarding_06.png",
              "alt": "Portforwarding Pirelli",
              "title": "Portforwarding Pirelli",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      